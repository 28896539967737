import { Ref } from 'vue';
import { gql } from 'graphql-tag';
import { uniqBy } from 'lodash';
import { useResult } from '@vue/apollo-composable';
import { FieldPolicy } from '@apollo/client';
import { toISODateString } from '@/util/time';
import { FRAGMENT_ADDRESS } from './addressFragments';
import {
  EventQuery,
  EventsQuery,
  LastEventQuery,
  FirstEventQuery
} from './eventQueries.generated';

export const fieldPolicy: { event: FieldPolicy } = {
  event: {
    keyArgs: ['sort', 'filter'],
    merge: (existing: any[] = [], incoming: any[], { readField }) =>
      uniqBy([...existing, ...incoming], (e) => readField('id', e))
  }
};

export const QUERY_EVENTS = gql`
  query events(
    $languageCode: String!
    $filter: event_filter
    $limit: Int
    $sort: [String!]
  ) {
    event(filter: $filter, limit: $limit, sort: $sort) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
      }

      start_date
      end_date

      start_time
      end_time

      address {
        ...AddressParts
      }

      categories {
        category_id {
          id
          color
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      image {
        id
      }
    }
  }
  ${FRAGMENT_ADDRESS}
`;

export const useResultEvents = (result: Ref<EventsQuery | undefined>) =>
  useResult(result, [], (data: EventsQuery) => data.event);

export const QUERY_EVENT__FIRST = gql`
  query firstEvent {
    event(sort: "start_date", limit: 1) {
      id
    }
  }
`;

export const QUERY_EVENT__LAST = gql`
  query lastEvent {
    event(sort: "-end_date", limit: 1) {
      id
    }
  }
`;

export const useResultFirstLastEvent = (
  result: Ref<FirstEventQuery | LastEventQuery | undefined>
) =>
  useResult(
    result,
    null,
    (data: FirstEventQuery | LastEventQuery) => data.event?.[0] || null
  );

export const QUERY_EVENT = gql`
  query event($id: ID!, $languageCode: String!) {
    event_by_id(id: $id) {
      id
      start_date
      start_time

      end_date
      end_time

      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        description
      }

      image {
        id
        filename_download
        description
      }

      address {
        ...AddressParts
      }

      categories {
        id
        category_id {
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            id
            name
          }
          color
        }
      }

      links {
        id
        link_id {
          url
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            id
            title
          }
        }
      }

      assets {
        id
        directus_files_id {
          id
          type
          filesize
          filename_download
        }
      }
    }
  }
  ${FRAGMENT_ADDRESS}
`;

export const useResultEvent = (result: Ref<EventQuery | undefined>) =>
  useResult(result, undefined, (data) => data.event_by_id);

/**
 * @param from time range start
 * @param to time range end
 * @returns a filter object that can be used to retrieve events that belong in the specified time range
 */
export const makeFilterTimeRange = (from: string | Date, to: string | Date) => {
  const fromToUse = typeof from === 'string' ? from : toISODateString(from);
  const toToUse = typeof to === 'string' ? to : toISODateString(to);

  return {
    _or: [
      {
        _and: [
          // begins before range start, ends after range start
          { start_date: { _lte: fromToUse } },
          { end_date: { _gte: fromToUse } }
        ]
      },
      {
        _and: [
          // starts in range
          { start_date: { _gte: fromToUse } },
          { start_date: { _lte: toToUse } }
        ]
      }
    ]
  };
};
