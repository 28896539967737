const messages = {
    en: {
        navigation: {
            close: "close",
            start: "start",
            destination: "destination:",
            navigation: "navigation",
            navigationText: "Click on \"Start\" to open the navigation. Come back to the app, if you have arrived."
        }
    },
    de: {
        navigation: {
            close: "schließen",
            start: "starten",
            destination: "Ziel:",
            navigation: "Navigation",
            navigationText: "Klicke auf \"Starten\", um die Navigation zu öffnen. Kehre zurück zur App, wenn du am Ziel angekommen bist!"
        }
    }
}

export default messages;