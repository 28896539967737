import * as Types from '../types.generated';

import gql from 'graphql-tag';
export type AddressPartsFragment = { __typename?: 'address', id: string, zip?: string | null, city?: string | null, street?: string | null, housenumber?: string | null, translations?: Array<{ __typename?: 'address_translations', id: string, title?: string | null, addition?: string | null } | null> | null };

export const AddressPartsFragmentDoc = gql`
    fragment AddressParts on address {
  id
  zip
  city
  street
  housenumber
  translations {
    id
    title
    addition
  }
}
    `;