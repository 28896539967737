import { Ref } from 'vue';
import { gql } from 'graphql-tag';
import { useResult } from '@vue/apollo-composable';

import {
  AllNewsArticlesQuery,
  FeaturedNewsArticlesQuery,
  NewsArticleQuery
} from './newsQueries.generated';
import { makeDefaultFieldPolicy } from '../util';

export const QUERY_ALL_NEWS_ARTICLES = gql`
  query allNewsArticles(
    $offset: Int
    $languageCode: String!
    $filter: news_article_filter
    $limit: Int
    $sort: [String!]
  ) {
    news_article(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      id
      date_published
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        excerpt
        content_words
      }
      image {
        id
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
    }
  }
`;

export const useResultAllNewsArticles = (
  result: Ref<AllNewsArticlesQuery | undefined>
) => useResult(result, [], (data) => data.news_article);

export const fieldPolicy = makeDefaultFieldPolicy('news_article');

export const QUERY_FEATURED_NEWS_ARTICLES = gql`
  query featuredNewsArticles($sort: [String!]!, $limit: Int, $languageCode: String!, $filter: news_article_filter) {
    news_article(filter: $filter, limit: $limit, sort: $sort) {
      id
      date_published
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        excerpt
        content_words
      }
      image {
        id
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
    }
  }
`;

export const useResultFeaturedNewsArticles = (
  result: Ref<FeaturedNewsArticlesQuery | undefined>
) => useResult(result, [], (data) => data.news_article);

export const QUERY_NEWS_ARTICLE = gql`
  query NewsArticle($id: ID!, $languageCode: String) {
    news_article_by_id(id: $id) {
      id
      date_published
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        content
        content_words
      }
      image {
        id
        description
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      link {
        url
        translations(
          filter: {
            languages_language_code: { language_code: { _eq: $languageCode } }
          }
        ) {
          title
        }
      }
      author {
        name
      }
    }
  }
`;

export const useResultNewsArticle = (
  result: Ref<NewsArticleQuery | undefined>
) => useResult(result, undefined, (data) => data.news_article_by_id);
