/* unplugin-vue-components disabled */export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
        "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discover"])},
        "tours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tours"])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
        "scanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scanner"])}
      },
      "de": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
        "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entdecken"])},
        "tours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touren"])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr"])},
        "scanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner"])}
      }
    }
  })
}
