import gql from 'graphql-tag';
import { Ref } from 'vue';
import { AttractionsQuery, AttractionQuery } from './attractionQueries.generated';
import { useResult } from '@vue/apollo-composable';
import { AddressPartsFragmentDoc } from './addressFragments.generated';
import { makeDefaultFieldPolicy } from '../util';

export const QUERY_ATTRACTIONS = gql`
  query attractions($offset: Int, $languageCode: String!, $sort: [String!]!, $limit: Int, $isHidden: Boolean) {
    attraction(offset: $offset, sort: $sort, limit: $limit, filter: { is_hidden: { _eq: $isHidden }}) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        excerpt
        description
      }
      image {
        id
      }
      is_hidden
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      address {
        ...AddressParts
      }
    }
  }
  ${AddressPartsFragmentDoc}`;

export const useResultAttractions = (
  result: Ref<AttractionsQuery | undefined>
) => useResult(result, [], (data: AttractionsQuery) => data.attraction);

export const fieldPolicy = makeDefaultFieldPolicy('attraction');

export const QUERY_ATTRACTION = gql`
  query Attraction($id: ID!, $languageCode: String) {
    attraction_by_id(id: $id) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        description
      }
      image {
        id
        description
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      assets {
        id
        directus_files_id {
          description
          id
          title
          type
        }
      }
    }
  }
`;

export const useResultAttraction = (
  result: Ref<AttractionQuery | undefined>
) => useResult(result, undefined, (data) => data.attraction_by_id);