import { FieldPolicy } from '@apollo/client/cache';

export const makeDefaultFieldPolicy = (
  name: string
): { [key: string]: FieldPolicy<any> } => ({
  [name]: {
    keyArgs: false,
    merge(existing: Record<string, any>[], incoming: Record<string, any>[]) {
      // create a list of id or __ref property
      const incomingIds = new Set(incoming.map(entity => entity.__ref || entity.id))
      // use existing entries except for those that are incoming (incoming is better)
      return [...(existing || []).filter(_ => !incomingIds.has(_.__ref || _.id)), ...incoming];
    }
  }
});
