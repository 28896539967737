import { useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { Ref } from 'vue';
import { makeDefaultFieldPolicy } from '../util';
import { AccommodationQuery, AccommodationsQuery } from './accommodationQueries.generated';
import { AddressPartsFragmentDoc } from './addressFragments.generated';

export const QUERY_ACCOMMODATIONS = gql`
  query accommodations($offset: Int, $languageCode: String!, $sort: [String!]!, $limit: Int) {
    accommodation(offset: $offset, sort: $sort, limit: $limit) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        excerpt
        description
      }
      image {
        id
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      address {
        ...AddressParts
      }
    }
  }
  ${AddressPartsFragmentDoc}`;

export const useResultAccommodations = (
  result: Ref<AccommodationsQuery | undefined>
) => useResult(result, [], (data: AccommodationsQuery) => data.accommodation);

export const fieldPolicy = makeDefaultFieldPolicy('accommodation');

export const QUERY_ACCOMMODATION = gql`
  query Accommodation($id: ID!, $languageCode: String) {
    accommodation_by_id(id: $id) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        description
      }
      image {
        id
        description
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      assets {
        id
        directus_files_id {
          description
          id
          title
          type
        }
      }
      link {
        id
        url
        translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
          id
          title
        }
      }
      address {
        ...AddressParts
      }
      contact {
        id
        first_name
        last_name
        company
        email
        phone
        url
        fax
        address {
          ...AddressParts
        }
      }
    }
  }
${AddressPartsFragmentDoc}`;

export const useResultAccommodation = (
  result: Ref<AccommodationQuery | undefined>
) => useResult(result, undefined, (data) => data.accommodation_by_id);