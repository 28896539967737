import gql from 'graphql-tag';
import { Ref } from 'vue';
import { useResult } from '@vue/apollo-composable';
import { ClassicTourQuery, ClassicToursQuery } from './classicTourQueries.generated';
import { AddressPartsFragmentDoc } from './addressFragments.generated';
import { makeDefaultFieldPolicy } from '../util';

export const QUERY_TOURS = gql`
  query classicTours($offset: Int, $languageCode: String!, $sort: [String!]!, $limit: Int) {
    classic_tour(offset: $offset, sort: $sort, limit: $limit) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        excerpt
        description
      }
      image {
        id
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      address {
        ...AddressParts
      }
    }
  }
  ${AddressPartsFragmentDoc}`;

export const useResultClassicTours = (
  result: Ref<ClassicToursQuery | undefined>
) => useResult(result, [], (data: ClassicToursQuery) => data.classic_tour);

export const fieldPolicy = makeDefaultFieldPolicy('classic_tour');

export const QUERY_CLASSIC_TOUR = gql`
  query classicTour($id: ID!, $languageCode: String) {
    classic_tour_by_id(id: $id) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        description
      }
      image {
        id
        description
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      address {
        ...AddressParts
      }
      contact {
        id
        first_name
        last_name
        company
        email
        phone
        url
        fax
        address {
          ...AddressParts
        }
      }
    }
  }
${AddressPartsFragmentDoc}`;

export const useResultClassicTour = (
  result: Ref<ClassicTourQuery | undefined>
) => useResult(result, undefined, (data) => data.classic_tour_by_id);