import { eachDayOfInterval, formatISO } from 'date-fns';

export const getReadingDuration = (wordCount: number) => {
  const WORDS_PER_MINUTE = 180;

  return Math.max(1, wordCount / WORDS_PER_MINUTE).toFixed();
};

export const toISODateString = (date: Date): string =>
  formatISO(date, { representation: 'date' }); // Date.prototype.toISOString() is not used to prevent issues concerning DST

export const eachDayOfIntervalWithIsoDateString = (
  from: string | Date,
  to: string | Date
): string[] => {
  const start = typeof from === 'string' ? new Date(from) : from;
  const end = typeof to === 'string' ? new Date(to) : to;

  return eachDayOfInterval({
    start,
    end
  }).map(toISODateString);
};
