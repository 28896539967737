import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/$icon',
    component: () => import('@/views/$icon.vue')
  },
  {
    path: '/$splash',
    component: () => import('@/views/$splash.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue')
      },
      {
        path: 'events',
        alias: 'event',
        component: () => import('@/views/EventsPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/EventsPage.vue')
          }
        ]
      },
      {
        path: 'news',
        alias: 'news_article', // push notifications use entity names
        component: () => import('@/views/NewsPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/NewsPage.vue')
          }
        ]
      },
      {
        path: 'attractions',
        alias: 'attraction',
        component: () => import('@/views/AttractionsPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/AttractionsPage.vue')
          }
        ]
      },
      {
        path: 'gastronomies',
        alias: 'gastronomy',
        component: () => import('@/views/GastronomiesPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/GastronomiesPage.vue')
          }
        ]
      },
      {
        path: 'accommodations',
        alias: 'accommodation',
        component: () => import('@/views/AccommodationsPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/AccommodationsPage.vue')
          }
        ]
      },
      {
        path: 'shops',
        alias: 'shop',
        component: () => import('@/views/ShopsPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/ShopsPage.vue')
          }
        ]
      },
      {
        path: 'classic-tours',
        alias: 'classic_tour',
        component: () => import('@/views/ClassicToursPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/ClassicToursPage.vue')
          }
        ]
      },
      {
        path: 'discover',
        component: () => import('@/views/DiscoverPage.vue')
      },
      {
        path: 'more',
        component: () => import('@/views/MorePage.vue')
      },
      {
        path: 'imprint',
        component: () => import('@/views/ImprintPage.vue')
      },
      {
        path: 'privacy',
        component: () => import('@/views/PrivacyPage.vue')
      },
      {
        path: 'interactive-tours',
        alias: 'interactive_tour',
        component: () => import('@/views/InteractiveToursPage.vue'),
        children: [
          {
            path: ':id',
            component: () => import('@/views/InteractiveToursPage.vue')
          }
        ]
      },
      {
        path: 'scanner',
        component: () => import('@/views/ScannerPage.vue')
      },
      {
        path: 'contact',
        component: () => import('@/views/ContactPage.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
