import { uniqBy } from 'lodash';
import gql from 'graphql-tag';
import { Ref } from 'vue';
import { useResult } from '@vue/apollo-composable';
import { ShopQuery, ShopsQuery } from './shopQueries.generated';
import { FieldPolicy } from '@apollo/client';
import { AddressPartsFragmentDoc } from './addressFragments.generated';
import { makeDefaultFieldPolicy } from '../util';

export const QUERY_SHOPS = gql`
  query shops($offset: Int, $languageCode: String!, $sort: [String!]!, $limit: Int) {
    shop(offset: $offset, sort: $sort, limit: $limit) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        excerpt
        description
      }
      image {
        id
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      address {
        ...AddressParts
      }
    }
  }
  ${AddressPartsFragmentDoc}`;

export const useResultShops = (result: Ref<ShopsQuery | undefined>) =>
  useResult(result, [], (data: ShopsQuery) => data.shop);

export const fieldPolicy = makeDefaultFieldPolicy('shop');


export const QUERY_SHOP = gql`
  query Shop($id: ID!, $languageCode: String) {
    shop_by_id(id: $id) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        description
      }
      image {
        id
        description
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      assets {
        id
        directus_files_id {
          description
          id
          title
          type
        }
      }
      link {
        id
        url
        translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
          id
          title
        }
      }
      address {
        ...AddressParts
      }
      contact {
        id
        first_name
        last_name
        company
        email
        phone
        url
        fax
        address {
          ...AddressParts
        }
      }
    }
  }
${AddressPartsFragmentDoc}`;

export const useResultShop = (
  result: Ref<ShopQuery | undefined>
) => useResult(result, undefined, (data) => data.shop_by_id);

