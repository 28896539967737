import { gql } from 'graphql-tag';

export const FRAGMENT_ADDRESS = gql`
  fragment AddressParts on address {
    id
    zip
    city
    street
    housenumber
    translations {
      id
      title
      addition
    }
  }
`;
