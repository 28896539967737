import { createApp, provide, h } from 'vue';
import App from './App.vue';

/**
 * Ionic
 */
import { IonicVue } from '@ionic/vue';

/* core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* theme variables */
import './theme/variables.css';

/* common styling */
import './assets/scss/index.scss';

import 'vue-global-api';

/**
 * Apollo
 */
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';

import { accessToken, backendUrl } from './util/env';
import { fieldPolicy as attractionFieldPolicy } from './apollo/apis/attractionQueries';
import { fieldPolicy as accomodationFieldPolicy } from './apollo/apis/accommodationQueries';
import { fieldPolicy as eventFieldPolicy } from './apollo/apis/eventQueries';
import { fieldPolicy as classicTourFieldPolicy } from './apollo/apis/classicTourQueries';
import { fieldPolicy as gastronomyFieldPolicy } from './apollo/apis/gastronomyQueries';
import { fieldPolicy as newsFieldPolicy } from './apollo/apis/newsQueries';
import { fieldPolicy as shopFieldPolicy } from './apollo/apis/shopQueries';


/**
 * I18n
 */
import messages from './locales/messages';

const httpLink = createHttpLink({
  uri: `${backendUrl}graphql?access_token=${accessToken}`
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...accomodationFieldPolicy,
        ...attractionFieldPolicy,
        ...classicTourFieldPolicy,
        ...eventFieldPolicy,
        ...gastronomyFieldPolicy,
        ...newsFieldPolicy,
        ...shopFieldPolicy
      }
    }
  }
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache
});

/**
 * vue-i18n
 */
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false,
  locale: 'de',
  messages
});

/**
 * router
 */
import router from './router';

/**
 * vue-virtual-scroller
 */
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';


import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import { getInternalUrlOrUndefined } from './util/navigation';

async function initPushNotifications() {
  // Listen for push notification events
  await PushNotifications.addListener('registration', token => {
    console.log('Registration token: ', token.value);
    FCM.getToken()
      .then((r) => {
        console.log(`FCM Token: ${r.token}`);
      })
      .catch((err) => console.log(err));
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', async notification => {
    const url = notification?.notification?.data?.url;
    if (url) {
      const internalUrl = getInternalUrlOrUndefined(url);
      if (internalUrl) {
        await router.isReady();
        router.push(internalUrl);
      } else {
        document.location.href = url;
      }
    }
  });

  // Register for push notifications
  await PushNotifications.requestPermissions();
  await PushNotifications.register();
  // Subscribe to news topic
  await FCM.subscribeTo({ topic: "app_v2" });
  await FCM.subscribeTo({ topic: "news_article" });
  await FCM.subscribeTo({ topic: "event" });
}

/**
 * app
 */
const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App)
})
  .use(IonicVue, { mode: 'md' })
  .use(router)
  .use(i18n)
  .use(VueVirtualScroller);

router.isReady().then(() => {
  app.mount('#app');
  initPushNotifications()
});
