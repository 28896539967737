import { useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { Ref } from 'vue';
import { makeDefaultFieldPolicy } from '../util';
import { AddressPartsFragmentDoc } from './addressFragments.generated';
import { GastronomiesQuery, GastronomyQuery } from './gastronomyQueries.generated';

export const QUERY_GASTRONOMIES = gql`
  query gastronomies($offset: Int, $languageCode: String!, $sort: [String!]!, $limit: Int) {
    gastronomy(offset: $offset, sort: $sort, limit: $limit) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        excerpt
        description
      }
      image {
        id
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      address {
        ...AddressParts
      }
    }
  }
  ${AddressPartsFragmentDoc}`;
  
export const useResultGastronomies = (
  result: Ref<GastronomiesQuery | undefined>
) => useResult(result, [], (data: GastronomiesQuery) => data.gastronomy);

export const fieldPolicy = makeDefaultFieldPolicy('gastronomy');


export const QUERY_GASTRONOMY = gql`
  query Gastronomy($id: ID!, $languageCode: String) {
    gastronomy_by_id(id: $id) {
      id
      translations(
        filter: {
          languages_language_code: { language_code: { _eq: $languageCode } }
        }
      ) {
        title
        description
      }
      image {
        id
        description
      }
      categories {
        id
        category_id {
          color
          id
          translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
            name
          }
        }
      }
      assets {
        id
        directus_files_id {
          description
          id
          title
          type
        }
      }
      link {
        id
        url
        translations(
            filter: {
              languages_language_code: { language_code: { _eq: $languageCode } }
            }
          ) {
          id
          title
        }
      }
      address {
        ...AddressParts
      }
      contact {
        id
        first_name
        last_name
        company
        email
        phone
        url
        fax
        address {
          ...AddressParts
        }
      }
    }
  }
${AddressPartsFragmentDoc}`;

export const useResultGastronomy = (
  result: Ref<GastronomyQuery | undefined>
) => useResult(result, undefined, (data) => data.gastronomy_by_id);