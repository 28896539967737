import { websiteUrls } from "./env";

/**
 * Turns an absolute url like https://duderstadt-entdecken.de/app/go/news/11 into /tabs/news/11 that can be directly used by the router.
 * If the host isn't part of the allowed websites or the path isn't specific enough, returns undefined
 * 
 * @param absoluteUrl The absolute url to get the internal url for
 * @returns string
 */
export const getInternalUrlOrUndefined = (absoluteUrl: string) => {
  if(!absoluteUrl) return;
  const supportedQRCodeWebsite = websiteUrls.find((url) => absoluteUrl.startsWith(`${url}/app/go`));
  if (supportedQRCodeWebsite) {
    const destination = absoluteUrl.split(`${supportedQRCodeWebsite}/app/go`)[1];
    return `/tabs${destination}`
  }
  return undefined
}
